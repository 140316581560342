export default {
    "enabled_auth_methods": [
        "loginPass"
    ],
    "auth_methods": {
        "loginPass": [],
        "openId": {
            "domain": "",
            "clientId": "",
            "redirectUri": "",
            "service": "app\\build\\cherkizovo\\auth\\services\\AuthOpenIdPKCEService",
            "authField": "email",
            "template": "componentsCompany/cherkizovo/auth/openId"
        }
    }
}