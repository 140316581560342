import {Constants} from "@/plugins/constants";
import librariesConfig from '@/../clientConfig/libraries'
import cardConfig from '@/../clientConfig/card'
import cardStagesConfig from '@/../clientConfig/cardStages'
import cardStageNegotiationsConfig from '@/../clientConfig/cardStageNegotiations'
import cardSectionsConfig from '@/../clientConfig/cardSections'
import cardStatusesConfig from '@/../clientConfig/cardStatuses'
import scalesConfig from '@/../clientConfig/scales'
import massActionsConfig from '@/../clientConfig/massActions'
import matrixConfig from '@/../clientConfig/matrix'
import settingsConfig from '@/../clientConfig/settings'
import userConfig from '@/../clientConfig/user'
import exportImportConfig from '@/../clientConfig/exportImport'
import authConfig from '@/../clientConfig/auth'
import i18nConfig from '@/../clientConfig/i18n'


const libraries = {
    getTypes(){
        return librariesConfig.types;
    },
    getTypeConfig(libraryType){
        return librariesConfig.types[libraryType];
    },
    getTypeShowName(libraryType){
        return this.getTypeConfig(libraryType).showName;
    },
    getElementsTableBorder(){
        return !!librariesConfig.elements_table_border;
    },
    getElementsTableColumns(libraryType){
        // Можем подсовывать разные конфигурации для разных библиотек
        if( !libraryType || !this.getTypeConfig(libraryType).columnsConfig ){
            return librariesConfig.elements_table_columns;
        }
        const configKeyName = this.getTypeConfig(libraryType).columnsConfig;
        return librariesConfig[configKeyName];
    },
    getElementChangePropagationMode(){
        return librariesConfig.element_change_propagation_mode;
    },
    getElementEditDialogWidth(){
        return librariesConfig.element_edit_dialog_width ? librariesConfig.element_edit_dialog_width : 40;
    },

    getFolderVisibilityConfigEnabled(){
        return librariesConfig.folder_visibility_config_enabled ? librariesConfig.folder_visibility_config_enabled : false;
    },
    getFolderVisibilityConfigMode(){
        return librariesConfig.folder_visibility_config_mode ? librariesConfig.folder_visibility_config_mode : Constants.library.folderVisibilityConfigMode.MODE_BLACKLIST;
    },
}

const card = {
    getAvailablePeriods(){
        return cardConfig.available_periods;
    },
    getAvailableSectionsByPeriod(cardPeriod){
        if( !cardConfig.available_sections_for_period[cardPeriod] ){
            return [];
        }
        return cardConfig.available_sections_for_period[cardPeriod];
    },
    getElementMediumFactsConfig(){
        if( !cardConfig.medium_facts ){
            return {'available': false};
        }
        return cardConfig.medium_facts;
    },

    getCardsPageFiltersTemplate(){
        let templatePath = cardConfig.cards_page_filters.template;
        return () => import(`@/${templatePath}`);
    },
    getCardSelectablePageColumns(){
        return cardConfig.card_selectable_page_columns;
    },
    getCardsPageColumns(){
        return cardConfig.cards_page_columns;
    },

    getCardHeaderElements(){
        return cardConfig.card_header_elements;
    },
    getCardHeaderTemplate(){
        if (!cardConfig.card_header_template) {
            return () => import(`@/components/cards/CardHeader/CardHeaderFromProps`);
        }
        const templatePath = cardConfig.card_header_template;
        return () => import(`@/${templatePath}`);
    },
    getCardCreateInfoTemplate(){
        if (!cardConfig.cards_create_info_template) {
            return null;
        }
        const templatePath = cardConfig.cards_create_info_template;
        return () => import(`@/${templatePath}`);
    },
}

const cardStages = {
    getTypes() {
        return Object.keys(cardStagesConfig.types);
    },
    getTypesConfig() {
        return cardStagesConfig.types;
    },
    getStageConfig(type) {
        return cardStagesConfig.types[type];
    },
    getStageShowName(type) {
        return cardStagesConfig.types[type].showName;
    },
    ifStageHideOnCardCreate(type) {
        return cardStagesConfig.types[type].hideOnCardCreate;
    },
    getDynamicStageButtonText(type) {
        return cardStagesConfig.types[type].dynamicAddButtonText;
    },
}


const cardStageNegotiations = {
    getDefaultNegotiationTypes() {
        return cardStageNegotiationsConfig.default_negotiations;
    },
    getNegotiationTypesForStage(stageType){
        if( cardStageNegotiationsConfig.stage_negotiations && cardStageNegotiationsConfig.stage_negotiations[stageType] ){
            return cardStageNegotiationsConfig.stage_negotiations[stageType];
        }
        return this.getDefaultNegotiationTypes();
    },


    getNegotiationTypesConfig() {
        return cardStageNegotiationsConfig.types;
    },
    getNegotiationNameByType(type) {
        return cardStageNegotiationsConfig.types[type]?.showName
    }
}

const cardStatuses = {
    getStatuses() {
        return cardStatusesConfig.types;
    },
    getStatusShowName(statusCode) {
        return this.getStatuses()[statusCode].showName;
    },
}


const cardSections = {
    getSections(){
        if( !cardSectionsConfig.sections ){
            return {};
        }
        return cardSectionsConfig.sections;
    },
    getSectionConfig(type){
        return this.getSections()[type];
    },
    getSectionShowName(type){
        return this.getSectionConfig(type).showName;
    },
    getSectionColumns(type){
        return this.getSectionConfig(type).columnsConfig
          ? cardSectionsConfig[this.getSectionConfig(type).columnsConfig]
          : cardSectionsConfig.default_columns
    },

    isSectionMultiplyElementsBonus(type){
        return !!this.getSectionConfig(type).multiplyElementsBonus;
    },

    hideOnStagesIfEmpty(sectionType, stageType){
        if( this.getSectionConfig(sectionType).hideOnStagesIfEmpty === undefined ){
            return false;
        }
        return this.getSectionConfig(sectionType).hideOnStagesIfEmpty.includes(stageType);
    },

    getSectionsBlockHeaderTemplate() {
        if (!cardSectionsConfig.sectionsBlockHeaderTemplate) {
            return () => import(`@/components/cards/CardElementsBlock/CardSectionsBlockHeader`);
        }
        const templatePath = cardSectionsConfig.sectionsBlockHeaderTemplate;
        return () => import(`@/${templatePath}`);
    },
    getSectionsBlockFooterTemplate() {
        if (!cardSectionsConfig.sectionsBlockFooterTemplate) {
            return () => import(`@/components/cards/CardElementsBlock/CardSectionsBlockFooter`);
        }
        const templatePath = cardSectionsConfig.sectionsBlockFooterTemplate;
        return () => import(`@/${templatePath}`);
    },
    getAllowedScaleTypesForSections(sectionType){
        if( this.getSectionConfig(sectionType).allowedScaleTypes === undefined ){
            return Object.keys(scales.getScales());
        }
        return this.getSectionConfig(sectionType).allowedScaleTypes;
    },
}


const scales = {
    getConfig(){
        return scalesConfig;
    },
    getScales(){
        return scalesConfig['scales'];
    },
    getScale(type){
        return this.getScales()[type];
    },

    getScalesForLibraryType(libraryType){
        // Нужно отфильтровать по разрешенным из конфигурации библиотеки
        const scalesToHide = libraries.getTypeConfig(libraryType).hideScales;
        if( !scalesToHide || scalesToHide.length < 1 ){
            return this.getScales();
        }

        return Object.keys(this.getScales())
          .filter(key => !scalesToHide.includes(key))
          .reduce((obj, key) => {
              obj[key] = this.getScales()[key];
              return obj;
          }, {});
    },

    getScaleConfig(scaleType){
        if( !scaleType ){
            return {};
        }
        return this.getScale(scaleType);
    },
    getScaleShowName(scaleType){
        if( !scaleType ){
            return '';
        }
        return this.getScale(scaleType).showName;
    },

    getScaleTemplate(scaleType, templateType){
        if( !scaleType ){
            return null;
        }
        return () => import(`@/${this.getScale(scaleType)['templatesFolder']}/${templateType}`);
    },

    getScalesSettingsTemplate(){
        if( !scalesConfig.scale_settings_template ){
            return null;
        }
        return () => import(`@/${this.getConfig()['scale_settings_template']}`);
    },
}

const massActions = {
    getEntities(){
        return massActionsConfig.entities;
    },
    getEntityActions(entityKey){
        if( !massActionsConfig.actions[entityKey] ){
            return [];
        }
        return massActionsConfig.actions[entityKey]
    },
    getReportBuilderEntityPreviewSettings(entityKey){
        return massActionsConfig.providers['report_builder']['entityPreviewSettings'][entityKey];
    },
    getActionTemplate(entityKey, actionKey){
        if( !this.getEntityActions(entityKey)[actionKey] ){
            return null;
        }
        return () => import(`@/${this.getEntityActions(entityKey)[actionKey].template}`);
    },
}


const matrix = {
    getConfig(){
        return matrixConfig;
    },
    getSummaryTemplate(){
        if( matrixConfig.summary_template){
            return () => import(`@/${this.getConfig().summary_template}`);
        }
        return () => import(`@/components/matrix/MatrixSummary`);
    },
    getSectionColumns(sectionType){
        return matrixConfig.sections_columns_config && matrixConfig.sections_columns_config[sectionType]
          ? matrixConfig[matrixConfig.sections_columns_config[sectionType]]
          : matrixConfig.default_columns
    },
}

const settings = {
    getTemplate(){
        let templatePath = settingsConfig.template;
        return () => import(`@/${templatePath}`);
    },

    getMenuRoutes(){
        let templatePath = settingsConfig.menu_routes;
        if( !templatePath ){
            return null;
        }
        return () => import(`@/${templatePath}`);
    },
    getMenuTemplate(){
        let templatePath = settingsConfig.menu_template;
        if( !templatePath ){
            return null;
        }
        return () => import(`@/${templatePath}`);
    },
    getActionsTemplate(){
        let templatePath = settingsConfig.actions_template;
        if( !templatePath ){
            return null;
        }
        return () => import(`@/${templatePath}`);
    },

    getDefaultNumberFormat(){
        return {
            decimalPoint: settingsConfig.default_number_format?.decimal_point || ',',
            decimalLength: settingsConfig.default_number_format?.decimal_length || 2,
        }
    },

    getElementsNameMaxLength(){
        if( settingsConfig.elements_name_max_length === 0 ){
            return false;
        }
        return settingsConfig.elements_name_max_length ? settingsConfig.elements_name_max_length : 255;
    },
}

const user = {
    getChiefTypes() {
        return Object.keys(userConfig.chief_types);
    },
    getChiefTypeShowName(type) {
        return userConfig.chief_types[type].showName;
    },
    getUsersListColumns(){
        return userConfig.users_list_columns;
    },
    getChiefsListColumns(){
        return userConfig.chiefs_list_columns;
    },

    isSelectByExcelEnabled(){
        return exportImport.getImportConfig('users_selection_by_excel')?.enabled || false;
    },

    getUsersListDefaultRoleFilterInSearch(){
        if( !userConfig.users_list_default_role_filter_in_search ){
            return null;
        }
        return userConfig.users_list_default_role_filter_in_search;
    },
}

const exportImport = {
    getImportConfigs() {
        return exportImportConfig.import;
    },
    getImportConfig(entity) {
        if( !exportImportConfig.import ){
            return [];
        }
        return exportImportConfig.import[entity] || [];
    },
    getExportConfigs() {
        return exportImportConfig.export;
    },
}

const auth = {
    getEnabledAuthMethods(){
        return authConfig.enabled_auth_methods;
    },
    getAuthMethodConfig(authMethodName){
        return authConfig.auth_methods[authMethodName];
    },
    getAuthMethodTemplate(authMethodName){
        if( !this.getAuthMethodConfig(authMethodName)){
            return null;
        }
        if( this.getAuthMethodConfig(authMethodName).template){
            return () => import(`@/${this.getAuthMethodConfig(authMethodName).template}`);
        }
        return () => import(`@/auth/methods/${authMethodName}`);
    },
    isRefreshEnabledForMethod(authMethodName){
        if( !authConfig.auth_methods[authMethodName] ){
            return false;
        }
        return !!authConfig.auth_methods[authMethodName].useRefreshToken;
    },
}

const i18n = {
    t(scope, key, defaultValue){
        if( !i18nConfig[scope] || !i18nConfig[scope][key] ){
            return defaultValue !== undefined ? defaultValue : scope+'-'+key;
        }
        return i18nConfig[scope][key];
    }
}


const helpers = {
    getTableColumnsArrayFromConfig(columnsConfig) {
        let toReturn = [];
        columnsConfig.forEach((columnConfig) => {
            toReturn.push({
                prop: columnConfig.prop,
                label: columnConfig.label,
                template: columnConfig.template
                    ? () => import(`@/${columnConfig.template}`)
                    : false,
                width: columnConfig.width ? columnConfig.width : null,
                minWidth: columnConfig.minWidth ? columnConfig.minWidth : null,
                align: columnConfig.align ? columnConfig.align : null,
                showOnStages: columnConfig.showOnStages,
                showOnStagesWhileNoNegotiation: columnConfig.showOnStagesWhileNoNegotiation,
                showOnCompleted: columnConfig.showOnCompleted,
                manualColumnTemplate: columnConfig.manualColumnTemplate ? true : null,
                getVif: (templateData) => {
                    if( columnConfig.if === undefined ) return true;
                    return eval(columnConfig.if)
                },
                getBind: (templateData, scope) => {
                    let toReturn = {};
                    if( !columnConfig.bind ) return toReturn;
                    for (const [key, value] of Object.entries(columnConfig.bind)) {
                        toReturn[key] = eval(value);
                    }
                    return toReturn;
                },
                getVon: (templateData, scope) => {
                    let toReturn = {};
                    if( !columnConfig.on ) return toReturn;
                    for (const [key, value] of Object.entries(columnConfig.on)) {
                        toReturn[key] = eval(value);
                    }
                    return toReturn;
                },
            });
        });
        return toReturn;
    },


        getObjectPropByString(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (o && k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    },

    getDateLocale(){ return 'ru-RU' },
    getDateLocaleOptions(){ return {} },

    buildUrl(url, parameters) {
        let qs = "";
        for (let key in parameters) {
            let value = parameters[key];
            qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1); //chop off last "&"
            url = url + "?" + qs;
        }
        return url;
    }
}


const companyConfiguration = {
    libraries,
    card,
    cardStages,
    cardStageNegotiations,
    cardStatuses,
    cardSections,
    scales,
    massActions,
    matrix,
    settings,
    user,
    auth,
    exportImport,
    helpers,
    i18n,
}

export default {
    install(Vue) {
        Vue.prototype.$companyConfiguration = companyConfiguration;
    },
    configs: companyConfiguration,
}