export default {
    "types": {
        "basic": {
            "showName": "Библиотека KPI"
        }
    },
    "use_scales_units_library": false,
    "element_change_propagation_mode": 2,
    "elements_table_columns": [
        {
            "label": "Наименование КПЭ",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit",
            "width": 100
        },
        {
            "label": "Шкала",
            "template": "components/tableColumnTemplates/library/LibraryElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope"
            },
            "width": 200
        },
        {
            "label": "Плановое значение",
            "prop": "scale.plan",
            "width": 100
        },
        {
            "label": "Фактическое значение",
            "prop": "scale.fact",
            "width": 100
        },
        {
            "label": "Взвешенный % выплаты по цели",
            "prop": "bonus",
            "width": 150
        }
    ]
}