export default {
    "scales": {
        "design": {
            "showName": "Проектная",
            "templatesFolder": "componentsCompany/cherkizovo/elementScales/design",
            "events": []
        },
        "progressive": {
            "showName": "Прогрессивная",
            "templatesFolder": "componentsCompany/cherkizovo/elementScales/progressive",
            "events": []
        }
    },
    "scales_id_threshold": 10000,
    "scale_settings_template": "componentsCompany/cherkizovo/scalesSettings/ScalesSettings"
}