export default {
    "export": {
        "library_folder": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\FolderExportService",
            "permissions": []
        },
        "card": {
            "class": "app\\domain\\Card\\services\\CardExportService",
            "permissions": []
        }
    },
    "import": {
        "library_folder": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\FolderImportService",
            "permissions": [],
            "example": "library.xlsx"
        },
        "card_elements": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\CardElementsImportService",
            "permissions": [],
            "example": "card.xlsx"
        },
        "users_selection_by_excel": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\UsersSelectionByExcelService",
            "enabled": true,
            "permissions": [],
            "example": "users_selection.xlsx"
        },
        "scale_parts": {
            "class": "app\\build\\cherkizovo\\exportImport\\services\\ScalePartsImportService",
            "permissions": [],
            "example": "scale_parts.xlsx"
        }
    }
}