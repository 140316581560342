<template>
  <div style="display: flex" v-loading="loading">
    <el-select
      v-model="currentTemplateId"
      no-data-text="Нет сохраненных шаблонов"
      placeholder="Выберите шаблон"
      size="mini"
      @change="templateSelected"
    >
      <el-option
        v-for="template in templates"
        :key="template.id"
        :label="template.name"
        :value="parseInt(template.id)">
      </el-option>
    </el-select>


    <el-button
      v-if="currentTemplateId && $canAndRulesPass(currentTemplate, $permissions.CARD_CREATE_TEMPLATES.UPDATE)"
      style="margin-left: 10px"
      size="mini"
      icon="fas fa-save"
      @click="saveCurrentTemplate"
    >
    </el-button>
    <el-popconfirm
      v-if="currentTemplateId && $canAndRulesPass(currentTemplate, $permissions.CARD_CREATE_TEMPLATES.DELETE)"
      style="margin-left:10px;"
      confirm-button-text='Да'
      cancel-button-text='нет'
      icon="el-icon-question"
      title="Вы уверены, что хотите удалить этот шаблон?"
      @confirm="deleteTemplate"
    >
      <el-button
        slot="reference"
        size="mini"
        icon="fas fa-trash"
      ></el-button>
    </el-popconfirm>
    <el-button
      v-if="$canAndRulesPass(user, $permissions.CARD_CREATE_TEMPLATES.CREATE)"
      size="mini"
      style="margin-left: 10px"
      @click="saveNewTemplate"
    >
      Сохранить как
    </el-button>


  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "card-create-templates",
  components: {},

  props: {
    card: {type: Object},
    sections: {type: Array},
    stages: {type: Object},
  },
  computed: {
    ...mapGetters(['user']),
    currentTemplate(){
      return this.currentTemplateId ? this.templates.find(t => t.id === this.currentTemplateId) : null;
    }
  },
  data() {
    return {
      loading: false,
      templates: [],
      currentTemplateId: null,
    }
  },
  mounted() {
    this.searchTemplates();
  },
  methods: {
    searchTemplates() {
      this.loading = true;
      this.$api.card.searchCardCreateTemplates({
        expand: 'permissions'
      })
        .then(({data, paginator, sorter}) => {
          this.templates = data.templates;
          // this.sorterFill(sorter);
          // this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    templateSelected() {
      this.$emit('template-selected', this.currentTemplate);
    },

    saveCurrentTemplate() {
      const template = this.templates.find(t => t.id === this.currentTemplateId);
      this.saveTemplate(this.currentTemplate.id, this.currentTemplate.name);
    },

    saveNewTemplate() {
      this.$prompt('Введите название шаблона', 'Сохранение нового шаблона', {
        confirmButtonText: 'Сохранить',
        cancelButtonText: 'Отмена',
      }).then(({value}) => {
        this.saveTemplate(null, value);
      })
    },

    saveTemplate(id, name) {
      this.loading = true;
      this.$api.card.saveCardCreateTemplate({
        id: id,
        name: name,
        settings: {
          card: this.card,
          stages: this.stages,
          sections: this.sections,
        },
      })
        .then((data) => {
          this.searchTemplates();
          this.currentTemplateId = data.template.id;
        })
        .catch(e => {
          this.loading = false;
        })
    },

    deleteTemplate() {
      this.loading = true;
      this.$api.card.deleteCardCreateTemplate({
        id: this.currentTemplateId,
      })
        .then((data) => {
          this.currentTemplateId = null;
          this.searchTemplates();
        })
    },

  }
}
</script>

<style>

</style>