export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/cherkizovo/CardSectionsBlockHeader",
    "sections": {
        "basic": {
            "showName": "Библиотечные цели. Выбираются из общей библиотеки",
            "emptyText": "Нет целей",
            "fromLibrary": "basic",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "skip_weight_validation": [],
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        },
        "manual": {
            "showName": "Личные цели. Формируются самостоятельно. Проектная шкала",
            "allowedScaleTypes": [
                "design"
            ],
            "emptyText": "Нет целей",
            "fieldsAvailableForEdit": {
                "name": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "skip_weight_validation": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.parent_id": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.unit": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.pay_100": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.pay_75": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.pay_50": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.plan": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ]
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "ID",
            "width": 40,
            "prop": "id"
        },
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Вес",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementWeightWithDisableTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Шкала",
            "width": 140,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Цель выполнена полностью (100%)",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_100\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Цель выполнена не полностью, незначительное отклонение (75%)",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_75\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Цель выполнена не полностью, допустимые отклонения (50%)",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_50\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Плановое значение",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "Взвешенный % выплат по цели",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus",
            "width": 100
        },
        {
            "label": "Ответственный",
            "width": 120,
            "prop": "scale.responsible"
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}